@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
      @apply bg-zinc-100
  }

  body{
    color: '#5a5252'
  }
  input {
    @apply focus-within:outline-none
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -webkit-border-radius: 0;
    -webkit-appearance: none;
  }

  button {
    @apply active:opacity-60
  }
}

/* Custom Quill Font Size Labels */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before { content: '8px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before { content: '9px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before { content: '10px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before { content: '12px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before { content: '14px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before { content: '16px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before { content: '20px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before { content: '24px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before { content: '32px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="42px"]::before { content: '42px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="54px"]::before { content: '54px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="68px"]::before { content: '68px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="84px"]::before { content: '84px'; }
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="98px"]::before { content: '98px'; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before { content: '8px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before { content: '9px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before { content: '10px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before { content: '12px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before { content: '14px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before { content: '16px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before { content: '20px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before { content: '24px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before { content: '32px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="42px"]::before { content: '42px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="54px"]::before { content: '54px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="68px"]::before { content: '68px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="84px"]::before { content: '84px'; }
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="98px"]::before { content: '98px'; }

.swiper-custom-bullet {
  height: 2px;
  width: 2px;
  background-color: white;
}

.swiper-custom-bullet.swiper-custom-bullet-active {
  height: 2px;
  width: 2px;
  background-color: orange;
}